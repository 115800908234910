<template>
  <div class="card-main" style="width: 802px;">
    <div class="card-logo">
      <transition-group name="slide" tag="div">
        <div v-for="(item, index) in items" :key="item.id" class="item" v-if="index === currentIndex">
          <div class="card-header">
            <div class="logo-msb">
            </div>
            <vue-seamless-scroll :data="item.noActionsDepartments" :class-option="classOption" class="dep-list">
              <ul class="ul-item">
                <li class="li-item" v-for="(item, index) in item.noActionsDepartments" :key="index">
                  {{ item.short_name||item.name }}
                </li>
              </ul>
            </vue-seamless-scroll>
          </div>

          <div class="card-body">
            <p class="font-body">
              {{item.name}}
            </p>
          </div>
          <div class="card-footer">
            <p v-if="item.executive_end_data">
              截止时间：{{item.executive_end_data+' 23:59'| formatTime}}
            </p>
            <p v-if="item.user.department">
              {{item.user.department.name}}&nbsp;&nbsp;{{item.published_at | formatTime}}&nbsp;&nbsp; 发布

            </p>
          </div>
        </div>
      </transition-group>

    </div>
  </div>

</template>


<script>
  import {
    getTasklist
  } from '@/api/task/index'

  export default {
    data() {
      return {
        classOption: {
          limitMoveNum: 3,
          step: 0.4,
          direction: 2,
        },

        page: {
          current: 1,
          size: 15,
          total: 0,
        },

        intervalTime: 30000, // 初始时间间隔，5秒
        currentIndex: 0, // 当前要显示的索引
        items: [{
          msbDeps: ["办公室", "乡村产业发展处", "机关党委", "农田处"],
          date: "4月6日",
          user: {
            depname: "农田建设处"
          },
          careat_date: "3天前",
          title: "根据内蒙古自治区财政厅和政务服务与数据管理局《关于梳理自治区本级信息化项目有关情况》的通知，请厅机关各处室局、厅属各二级单位上报目前在用的信息化项目（系统）以及在建信息化项目（系统）两项内容，本次上报涉及2025年相关经费保障审批，如因各部门、各单位自身原因导致错报、漏报、晚报项目，影响下一年相关经费保障的，由各部门、各单位自行负责，请各部门、各单位高度重视此次信息化项目梳理工作，严格按照填报要求，认真、据实填写。表格提交截止时间为3月28日下班前。 联系电话： 6652002。",
        }],
      }
    },
    created() {
      this.fetchList()
      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.items.length;
      }, this.intervalTime);

      setInterval(() => {
        this.items = []
        this.fetchList()
        this.currentIndex = 0
      }, 300000); //获取数据
    },
    filters: {
      formatTime(timestamp) {
        const now = new Date();
        const targetTime = new Date(timestamp);
        const diff = Math.abs(now - targetTime);
        const minute = 60 * 1000;
        const hour = minute * 60;
        const day = hour * 24;
        const week = day * 7;

        if (diff < minute) {
          return Math.floor(diff / 1000) + '秒' + (now > targetTime ? '前' : '后');
        } else if (diff < hour) {
          return Math.floor(diff / minute) + '分钟' + (now > targetTime ? '前' : '后');
        } else if (diff < day) {
          return Math.floor(diff / hour) + '小时' + (now > targetTime ? '前' : '后');
        } else if (diff < week) {
          return Math.floor(diff / day) + '天' + (now > targetTime ? '前' : '后');
        } else {
          const month = targetTime.getMonth() + 1;
          const dayOfMonth = targetTime.getDate();
          return month + '月' + dayOfMonth + '日';
        }
      }
    },
    methods: {

      fetchList() {
        this.tableLoading = true
        const params = "进行中"
        getTasklist(params)
          .then(res => {
            debugger
            this.items = res.data
            this.page.total = res.total
          })
          .finally(() => {
            this.tableLoading = false
          })
      },

    },
  }
</script>

<style lang="scss" scoped>
  .card-main {

    font-family: "PingFangSC-Regular", "苹方-简", "微软雅黑", 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 8px;

    .card-logo {
      background-image: url("~@/assets/images/dp/mashangban.png");
      background-repeat: no-repeat;
    }

    .card-header {
      display: flex;

      .logo-msb {

        height: 178px;
        width: 178px;

        margin-right: 16px;

      }

      .dep-list {
        width: 580px;
        padding: 16px;
        padding-left: 0px;
        overflow: hidden;
        white-space: nowrap;
        position: relative;

        & span {}

        & ul {
          list-style: none;
          padding: 0;
          margin: 0 auto;

          &.ul-item {
            display: flex;

            .li-item {
              background-color: #FCEEEF;
              border-radius: 10px;
              padding: 16px;
              font-size: 30px;
              line-height: 42px;
              font-weight: 600;
              color: #F25642;
              margin-right: 16px;
              text-align: center;
            }
          }
        }
      }

    }

    .card-body {
      background-color: #FFF0F0;
      height: 280px;
      width: 770px;
      border-radius: 20px;
      margin: 8px;
      margin-top: -68px;
      padding: 16px;


      .font-body {
        font-size: 34px;
        color: #212121;
        line-height: 50px;
        overflow: hidden;
        display: -webkit-box;
        /* 将元素作为块级元素进行布局 */
        -webkit-line-clamp: 5;
        /* 限制最大显示行数为3行 */
        -webkit-box-orient: vertical;
        /* 垂直方向展开子元素 */
        text-overflow: ellipsis;
      }
    }

    .card-footer {
      display: flex;
      padding-left: 16px;
      padding-right: 16px;
      justify-content: space-between;
      color: #999;
      font-size: 26px;

    }
  }

  .slide-enter-active {
    transition: transform 0.1s, opacity 1s;
  }

  .slide-enter,
  .slide-leave-to

  /* .slide-leave-active in <2.1.8 */
    {
    opacity: 0;
    transform: translateY(100%);
  }
</style>
